exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cfp-js": () => import("./../../../src/pages/cfp.js" /* webpackChunkName: "component---src-pages-cfp-js" */),
  "component---src-pages-coc-js": () => import("./../../../src/pages/coc.js" /* webpackChunkName: "component---src-pages-coc-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-diversity-js": () => import("./../../../src/pages/diversity.js" /* webpackChunkName: "component---src-pages-diversity-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hotel-js": () => import("./../../../src/pages/hotel.js" /* webpackChunkName: "component---src-pages-hotel-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-program-index-js": () => import("./../../../src/pages/program/index.js" /* webpackChunkName: "component---src-pages-program-index-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-speakers-index-js": () => import("./../../../src/pages/speakers/index.js" /* webpackChunkName: "component---src-pages-speakers-index-js" */),
  "component---src-pages-sponsor-us-js": () => import("./../../../src/pages/sponsor-us.js" /* webpackChunkName: "component---src-pages-sponsor-us-js" */),
  "component---src-pages-sustainability-js": () => import("./../../../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-travel-js": () => import("./../../../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-pages-values-js": () => import("./../../../src/pages/values.js" /* webpackChunkName: "component---src-pages-values-js" */),
  "component---src-templates-session-js-content-file-path-content-program-a-clash-of-domains-3-case-studies-in-regulated-industries-to-help-us-understand-integrations-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/a-clash-of-domains-3-case-studies-in-regulated-industries-to-help-us-understand-integrations.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-a-clash-of-domains-3-case-studies-in-regulated-industries-to-help-us-understand-integrations-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-a-model-is-not-enough-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/a-model-is-not-enough.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-a-model-is-not-enough-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-advanced-software-architecture-with-residuality-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/advanced-software-architecture-with-residuality.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-advanced-software-architecture-with-residuality-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-advanced-software-teaming-mob-programming-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/advanced-software-teaming-mob-programming.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-advanced-software-teaming-mob-programming-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-aggregates-an-in-depth-examination-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/aggregates-an-in-depth-examination.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-aggregates-an-in-depth-examination-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-apis-as-semi-permeable-osmotic-interfaces-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/apis-as-semi-permeable-osmotic-interfaces.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-apis-as-semi-permeable-osmotic-interfaces-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-architecture-the-hard-parts-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/architecture-the-hard-parts.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-architecture-the-hard-parts-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-are-anaemic-domain-models-really-considered-harmful-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/are-anaemic-domain-models-really-considered-harmful.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-are-anaemic-domain-models-really-considered-harmful-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-building-distributed-system-blueprints-using-ddd-practices-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/building-distributed-system-blueprints-using-ddd-practices.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-building-distributed-system-blueprints-using-ddd-practices-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-code-reading-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/code-reading.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-code-reading-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-collaboratively-mapping-trade-offs-for-decision-making-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/collaboratively-mapping-trade-offs-for-decision-making.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-collaboratively-mapping-trade-offs-for-decision-making-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-ddd-and-llms-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/ddd-and-llms.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-ddd-and-llms-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-ddd-and-rebac-revolutionizing-access-management-with-a-business-centric-approach-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/ddd-and-rebac-revolutionizing-access-management-with-a-business-centric-approach.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-ddd-and-rebac-revolutionizing-access-management-with-a-business-centric-approach-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-ddd-bookswap-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/ddd-bookswap.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-ddd-bookswap-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-ddd-on-the-frontend-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/ddd-on-the-frontend.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-ddd-on-the-frontend-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-decision-making-for-maximizing-agreeability-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/decision-making-for-maximizing-agreeability.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-decision-making-for-maximizing-agreeability-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-design-discovery-in-existing-systems-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/design-discovery-in-existing-systems.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-design-discovery-in-existing-systems-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-disentangling-transactions-in-legacy-systems-how-to-open-pandoras-box-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/disentangling-transactions-in-legacy-systems-how-to-open-pandoras-box.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-disentangling-transactions-in-legacy-systems-how-to-open-pandoras-box-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-distilling-your-personal-design-heuristics-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/distilling-your-personal-design-heuristics.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-distilling-your-personal-design-heuristics-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-domain-driven-api-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/domain-driven-api-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-domain-driven-api-design-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-domain-pattern-how-the-domain-influences-the-architecture-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/domain-pattern-how-the-domain-influences-the-architecture.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-domain-pattern-how-the-domain-influences-the-architecture-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-domain-re-discovery-patterns-for-legacy-code-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/domain-re-discovery-patterns-for-legacy-code.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-domain-re-discovery-patterns-for-legacy-code-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-domain-storytelling-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/domain-storytelling.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-domain-storytelling-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-effective-test-automation-for-developers-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/effective-test-automation-for-developers.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-effective-test-automation-for-developers-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-elevating-tactical-ddd-patterns-through-object-calisthenics-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/elevating-tactical-ddd-patterns-through-object-calisthenics.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-elevating-tactical-ddd-patterns-through-object-calisthenics-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-eventstorming-masterclass-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/eventstorming-masterclass.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-eventstorming-masterclass-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-every-event-everywhere-all-at-once-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/every-event-everywhere-all-at-once.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-every-event-everywhere-all-at-once-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-evolutionary-architecture-and-the-role-of-ddd-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/evolutionary-architecture-and-the-role-of-ddd.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-evolutionary-architecture-and-the-role-of-ddd-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-evolving-wikipedia-a-case-study-in-applying-domain-driven-design-in-a-challenging-system-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/evolving-wikipedia-a-case-study-in-applying-domain-driven-design-in-a-challenging-system.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-evolving-wikipedia-a-case-study-in-applying-domain-driven-design-in-a-challenging-system-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-example-mapping-slice-any-story-into-testable-examples-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/example-mapping-slice-any-story-into-testable-examples.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-example-mapping-slice-any-story-into-testable-examples-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-experience-ensemble-mob-ways-of-working-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/experience-ensemble-mob-ways-of-working.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-experience-ensemble-mob-ways-of-working-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-harmonizing-complexity-balancing-abstractions-in-platforms-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/harmonizing-complexity-balancing-abstractions-in-platforms-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-harmonizing-complexity-balancing-abstractions-in-platforms-design-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-how-to-dsl-for-real-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/how-to-dsl-for-real.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-how-to-dsl-for-real-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-how-to-understand-almost-anything-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/how-to-understand-almost-anything.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-how-to-understand-almost-anything-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-impact-mapping-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/impact-mapping.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-impact-mapping-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-implementing-tactical-patterns-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/implementing-tactical-patterns.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-implementing-tactical-patterns-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-interviewing-stakeholders-how-to-get-the-most-and-best-requirements-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/interviewing-stakeholders-how-to-get-the-most-and-best-requirements.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-interviewing-stakeholders-how-to-get-the-most-and-best-requirements-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-knowledge-crunching-and-psychological-safety-from-myth-to-evidence-based-practices-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/knowledge-crunching-and-psychological-safety-from-myth-to-evidence-based-practices.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-knowledge-crunching-and-psychological-safety-from-myth-to-evidence-based-practices-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-leveraging-a-good-domain-model-to-create-a-quality-assurance-program-from-scratch-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/leveraging-a-good-domain-model-to-create-a-quality-assurance-program-from-scratch.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-leveraging-a-good-domain-model-to-create-a-quality-assurance-program-from-scratch-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-liberating-structures-tap-into-the-collective-ddd-knowledge-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/liberating-structures-tap-into-the-collective-ddd-knowledge.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-liberating-structures-tap-into-the-collective-ddd-knowledge-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-mob-programming-software-teaming-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/mob-programming-software-teaming.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-mob-programming-software-teaming-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-model-rigor-you-say-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/model-rigor-you-say.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-model-rigor-you-say-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-modelling-the-law-turning-intent-into-code-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/modelling-the-law-turning-intent-into-code.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-modelling-the-law-turning-intent-into-code-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-modelling-up-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/modelling-up.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-modelling-up-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-modern-trade-off-analysis-for-software-architecture-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/modern-trade-off-analysis-for-software-architecture.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-modern-trade-off-analysis-for-software-architecture-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-multiple-models-with-multiple-perspectives-in-a-cross-functional-team-case-study-from-healthcare-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/multiple-models-with-multiple-perspectives-in-a-cross-functional-team-case-study-from-healthcare.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-multiple-models-with-multiple-perspectives-in-a-cross-functional-team-case-study-from-healthcare-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-naming-is-caring-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/naming-is-caring.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-naming-is-caring-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-one-domain-from-different-angles-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/one-domain-from-different-angles.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-one-domain-from-different-angles-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-production-grade-event-sourcing-modelling-devops-process-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/production-grade-event-sourcing-modelling-devops-process.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-production-grade-event-sourcing-modelling-devops-process-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-push-your-side-effects-to-the-edge-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/push-your-side-effects-to-the-edge.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-push-your-side-effects-to-the-edge-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-reverse-engineering-the-raft-distributed-consensus-algorithm-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/reverse-engineering-the-raft-distributed-consensus-algorithm.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-reverse-engineering-the-raft-distributed-consensus-algorithm-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-shades-of-conways-law-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/shades-of-conways-law.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-shades-of-conways-law-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-software-design-masterclass-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/software-design-masterclass.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-software-design-masterclass-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-systems-thinking-for-innovation-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/systems-thinking-for-innovation.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-systems-thinking-for-innovation-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-take-a-mess-make-a-mess-fix-the-mess-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/take-a-mess-make-a-mess-fix-the-mess.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-take-a-mess-make-a-mess-fix-the-mess-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-team-topologies-masterclass-success-patterns-for-fast-flow-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/team-topologies-masterclass-success-patterns-for-fast-flow.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-team-topologies-masterclass-success-patterns-for-fast-flow-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-team-topology-a-tool-a-theory-framework-or-art-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/team-topology-a-tool-a-theory-framework-or-art.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-team-topology-a-tool-a-theory-framework-or-art-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-technical-debt-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/technical-debt.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-technical-debt-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-the-change-starts-now-accelerating-essents-future-with-domain-driven-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/the-change-starts-now-accelerating-essents-future-with-domain-driven-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-the-change-starts-now-accelerating-essents-future-with-domain-driven-design-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-the-intersection-of-architecture-and-implementation-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/the-intersection-of-architecture-and-implementation.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-the-intersection-of-architecture-and-implementation-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-the-timeless-way-of-software-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/the-timeless-way-of-software.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-the-timeless-way-of-software-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-thinking-about-thinking-dogma-what-are-we-to-do-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/thinking-about-thinking-dogma-what-are-we-to-do.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-thinking-about-thinking-dogma-what-are-we-to-do-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-uncovering-and-optimizing-feedback-loops-together-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/uncovering-and-optimizing-feedback-loops-together.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-uncovering-and-optimizing-feedback-loops-together-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-untangling-consequences-causality-patterns-for-effective-multi-context-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/untangling-consequences-causality-patterns-for-effective-multi-context-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-untangling-consequences-causality-patterns-for-effective-multi-context-design-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-upskilling-your-team-in-ddd-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/upskilling-your-team-in-ddd.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-upskilling-your-team-in-ddd-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-using-the-iceberg-model-to-improve-sociotechnical-systems-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/using-the-iceberg-model-to-improve-sociotechnical-systems.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-using-the-iceberg-model-to-improve-sociotechnical-systems-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-what-we-know-we-dont-know-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/what-we-know-we-dont-know.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-what-we-know-we-dont-know-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-what-we-learned-about-event-sourcing-and-ddd-while-building-a-bank-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/what-we-learned-about-event-sourcing-and-ddd-while-building-a-bank.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-what-we-learned-about-event-sourcing-and-ddd-while-building-a-bank-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-youve-done-an-eventstorming-great-now-what-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/program/youve-done-an-eventstorming-great-now-what.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-youve-done-an-eventstorming-great-now-what-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-aki-salmi-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/aki-salmi.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-aki-salmi-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-alberto-brandolini-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/alberto-brandolini.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-alberto-brandolini-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-annegret-junker-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/annegret-junker.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-annegret-junker-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-ayesha-bagus-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/ayesha-bagus.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-ayesha-bagus-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-barry-oreilly-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/barry-oreilly.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-barry-oreilly-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-brian-loomis-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/brian-loomis.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-brian-loomis-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-carola-lilienthal-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/carola-lilienthal.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-carola-lilienthal-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-chelsea-troy-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/chelsea-troy.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-chelsea-troy-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-chris-atherton-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/chris-atherton.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-chris-atherton-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-craig-mccallum-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/craig-mccallum.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-craig-mccallum-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-cyrille-martraire-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/cyrille-martraire.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-cyrille-martraire-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-dawn-ahukanna-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/dawn-ahukanna.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-dawn-ahukanna-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-ddd-europe-academy-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/ddd-europe-academy.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-ddd-europe-academy-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-diana-montalion-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/diana-montalion.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-diana-montalion-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-dorra-bartaguiz-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/dorra-bartaguiz.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-dorra-bartaguiz-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-elizabeth-zagroba-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/elizabeth-zagroba.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-elizabeth-zagroba-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-eric-evans-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/eric-evans.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-eric-evans-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-frank-rehfeldt-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/frank-rehfeldt.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-frank-rehfeldt-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-gien-verschatse-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/gien-verschatse.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-gien-verschatse-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-halvor-grizzly-bjorn-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/halvor-grizzly-bjorn.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-halvor-grizzly-bjorn-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-hana-amiri-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/hana-amiri.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-hana-amiri-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-helen-rapp-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/helen-rapp.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-helen-rapp-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-henning-schwentner-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/henning-schwentner.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-henning-schwentner-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-hillel-wayne-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/hillel-wayne.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-hillel-wayne-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-ivo-domburg-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/ivo-domburg.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-ivo-domburg-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jacob-duijzer-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/jacob-duijzer.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jacob-duijzer-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jacqui-read-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/jacqui-read.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jacqui-read-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jennifer-jd-carlston-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/jennifer-jd-carlston.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jennifer-jd-carlston-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-joao-rosa-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/joao-rosa.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-joao-rosa-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-joep-schuurkes-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/joep-schuurkes.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-joep-schuurkes-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-kate-chapman-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/kate-chapman.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-kate-chapman-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-kevin-meadows-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/kevin-meadows.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-kevin-meadows-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-krisztina-hirth-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/krisztina-hirth.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-krisztina-hirth-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-lorraine-steyn-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/lorraine-steyn.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-lorraine-steyn-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-marijn-huizendveld-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/marijn-huizendveld.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-marijn-huizendveld-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-marit-van-dijk-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/marit-van-dijk.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-marit-van-dijk-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-mark-richards-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/mark-richards.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-mark-richards-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-markus-volter-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/markus-volter.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-markus-volter-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-martin-gunther-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/martin-gunther.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-martin-gunther-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-masoud-bahrami-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/masoud-bahrami.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-masoud-bahrami-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-matt-wynne-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/matt-wynne.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-matt-wynne-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-max-arshinov-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/max-arshinov.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-max-arshinov-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-maxime-sanglan-charlier-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/maxime-sanglan-charlier.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-maxime-sanglan-charlier-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-michael-feathers-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/michael-feathers.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-michael-feathers-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-moriel-schottlender-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/moriel-schottlender.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-moriel-schottlender-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-mufrid-krilic-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/mufrid-krilic.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-mufrid-krilic-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-natalia-szymanska-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/natalia-szymanska.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-natalia-szymanska-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-neal-ford-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/neal-ford.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-neal-ford-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-nico-krijnen-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/nico-krijnen.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-nico-krijnen-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-oskar-dudycz-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/oskar-dudycz.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-oskar-dudycz-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-pamela-paterson-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/pamela-paterson.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-pamela-paterson-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-pauline-jamin-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/pauline-jamin.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-pauline-jamin-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-pim-elshoff-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/pim-elshoff.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-pim-elshoff-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-rebecca-parsons-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/rebecca-parsons.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-rebecca-parsons-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-rebecca-wirfs-brock-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/rebecca-wirfs-brock.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-rebecca-wirfs-brock-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-richard-gross-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/richard-gross.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-richard-gross-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-sarah-wells-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/sarah-wells.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-sarah-wells-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-stefan-hofer-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/stefan-hofer.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-stefan-hofer-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-sun-maria-lehmann-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/sun-maria-lehmann.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-sun-maria-lehmann-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-talysson-oliveira-cassiano-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/talysson-oliveira-cassiano.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-talysson-oliveira-cassiano-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-taylor-troesh-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/taylor-troesh.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-taylor-troesh-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-thierry-de-pauw-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/thierry-de-pauw.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-thierry-de-pauw-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-thomas-bogh-fangel-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/thomas-bogh-fangel.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-thomas-bogh-fangel-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-thomas-coopman-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/thomas-coopman.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-thomas-coopman-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-woody-zuill-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.dddeurope.com/2025.dddeurope.com/content/speakers/woody-zuill.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-woody-zuill-mdx" */)
}

